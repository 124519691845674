import React from "react";
import { useState } from "react";
import axios from "axios";

import {
    Container,
    Paper,
    TextField,
    Button,
    Avatar,
    Typography,
    CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LockOutlined as LockOutlinedIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(5),
        marginTop: theme.spacing(12),
    },
}));

function Login(props) {
    const classes = useStyles();

    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const login = () => {
        setLoading(true);
        axios
            .post(
                "/api/customer/login",
                { username: email, password: password },
                {
                    headers: { "Content-Type": "application/json" },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    window.location.href = "/app";
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onKeyUp = (e) => {
        if (e.key === "Enter") {
            login();
        }
    };

    return (
        <Container component="main" maxWidth="sm">
            <Paper className={classes.paper} elevation={3}>
                <Avatar>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <TextField
                    required
                    fullWidth
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    onKeyUp={onKeyUp}
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    autoComplete="email"
                />
                <TextField
                    required
                    fullWidth
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                    onKeyUp={onKeyUp}
                    label="Password"
                    margin="normal"
                    variant="outlined"
                    type="password"
                    autoComplete="current-password"
                />
                <Button
                    fullWidth
                    onClick={(e) => {
                        login();
                    }}
                    disabled={loading}
                    variant="contained"
                    color="primary"
                >
                    <span>Login</span>
                    {loading ? <CircularProgress size="1rem" /> : null}
                </Button>
            </Paper>
        </Container>
    );
}

export default Login;
